import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { BlogArticleType } from "../../types/blog-types"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import { stringToPath } from "../../utilities/string-path-convertor"
import RedingTime from "./reading-time"

const BlogArticleHorizontalBox = ({ data }: { data: BlogArticleType }) => {
  if (!data.compose__page) {
    return null
  }

  let backgroundColor = data.color
  let title = data.compose__page[0].title
  let image = (
    <GatsbyImage
      image={getImage(data.image.localFile.childImageSharp.gatsbyImageData)!}
      alt={data.image.title}
    />
  )

  return (
    <div
      className='blog-article-first mb-20 xl:mb-32 mt-10 xl:mt-20 relative'
      style={{ backgroundColor: backgroundColor }}
    >
      <div className='blog-article-info'>
        <h2>
          <Link to={`/blog/${data.compose__page[0].slug}/`}>{title}</Link>
        </h2>
        <div className='blog-article-description'>
          {documentToPlainTextString(JSON.parse(data.text.raw))}
        </div>
        <div className='bottom-article-info hidden xl:block'>
          <div className='blog-article-date'>{data.createdAt}</div>
          {data.category && 
            <div className='font-bold capitalize'>
              <Link to={`/blog/${stringToPath(data.category.name)}/`}>
                {data.category.name.toLowerCase()}
              </Link>
            </div>
          }
          <div>
            <RedingTime text={data.text.raw} />
          </div>
        </div>
      </div>
      <div className='blog-article-image'>
        <Link to={`/blog/${data.compose__page[0].slug}/`}>{image}</Link>
      </div>
    </div>
  )
}

export default BlogArticleHorizontalBox
