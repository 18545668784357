import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { BlogArticleType } from "../../types/blog-types"
import { stringToPath } from "../../utilities/string-path-convertor"
import RedingTime from "./reading-time"

const BlogArticleBox = ({
  article,
  smallImage = false,
}: {
  article: BlogArticleType
  smallImage?: boolean
}) => {
  const linkToArticle = `/blog/${article.compose__page[0].slug}/`
  return (
    <div className='blog-article-box'>
      <Link
        className='blog-article-image-container flex justify-center items-center'
        to={linkToArticle}
        style={{ backgroundColor: article.color }}
      >
        <div
          className={`blog-article-image${
            smallImage ? " xl:w-[150px] xl:p-2" : ""
          }`}
        >
          <GatsbyImage
            image={getImage(article.image.localFile.childImageSharp)!}
            alt={article.image.title}
          />
        </div>
      </Link>
      <div className='blog-article-info'>
        <div className='bottom-article-info hidden xl:block'>
          <div className='font-bold capitalize'>
            <Link to={`/blog/${stringToPath(article.category.name)}/`}>
              {article.category.name.toLowerCase()}
            </Link>
          </div>
          <div>
            <RedingTime text={article.text.raw} />
          </div>
        </div>
        <h2 className='mt-2'>
          <Link to={linkToArticle}>{article.compose__page[0].title}</Link>
        </h2>
        <div className='blog-article-description line-clamp-3'>
          {documentToPlainTextString(JSON.parse(article.text.raw))}
        </div>
        <div className='flex space-x-5 mt-5 text-[14px]'>
          {article.author && (
            <div className='blog-article-author'>{article.author.name}</div>
          )}
          <div className='blog-article-date'>{article.createdAt}</div>
        </div>
      </div>
    </div>
  )
}

export default BlogArticleBox
