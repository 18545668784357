import React from "react"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { useReadingTime } from "react-hook-reading-time"

const RedingTime = ({ text }: { text: any }) => {
  const plainText = documentToPlainTextString(JSON.parse(text))
  const readingTimeObj = useReadingTime(plainText)

  return readingTimeObj.text
}

export default RedingTime
