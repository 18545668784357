import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { ContentfulBlogCategoryType } from "../../types/blog-types"
import { stringToPath } from "../../utilities/string-path-convertor"
import { useLocation } from "@reach/router"

const BlogCategories = ({
  activeCategory = "",
}: {
  activeCategory?: string
}) => {
  const location = useLocation()
  const {
    allContentfulBlogCategoy: { edges: categories },
  }: ContentfulBlogCategoryType = useStaticQuery(graphql`
    query {
      allContentfulBlogCategoy(sort: { order: ASC, fields: createdAt }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  return (
    <div className='blog-categories-container'>
      <div className='inner-blog-categories'>
        {categories.map(({ node: category }, index) => {
          return (
            <div
              className={`blog-category capitalize${
                location.pathname === `/blog/${stringToPath(category.name)}` ||
                activeCategory === category.name
                  ? " font-bold"
                  : ""
              }`}
              key={category.id}
            >
              <Link to={`/blog/${stringToPath(category.name)}/`}>
                {category.name.toLowerCase()}
              </Link>
              {index !== categories.length - 1 && (
                <div className='font-normal'>|</div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BlogCategories
