import React from "react";
import { Link } from "gatsby";

const BreadCrumbs = ({ crumbs, pipeline = false, className = '' }) => {
  return (
    <div className={`text-[12px] xl:text-[14px] capitalize flex items-center space-x-3 ${className}`}>
      {crumbs.map((crumb, index) => (
        <>
          {index === crumbs.length - 1 && !crumb.hasLink ? (
            <span key={index}>{crumb.label}</span>
          ) : (
            <>
              <Link key={index} to={crumb.to} className="underline">
                {crumb.label}
              </Link>
              {!(index === crumbs.length - 1) && <span>{pipeline ? '|' : '/'}</span>}
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default BreadCrumbs;
